import React from 'react'
import { useAuth } from '../utils/auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  Link,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Checkbox,
  Button,
  useColorModeValue
} from '@chakra-ui/react'

import { usePostRequest } from '../utils/hooks/usePostRequest';
import { useCustomToast } from "../utils/hooks/toast.js";

import { Field, Form, Formik } from 'formik';
import FormErrorMessage from '../components/CustomFormErrorMessage';


const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Le nom d\'utilisateur est obligatoire'; 
  }
  if (!values.password) {
    errors.password = 'Le mot de passe est obligatoire';
  }
  return errors;
};


const Login = () => {
  return (
    <LoginArea />
  )
}


// Update the LoginArea component to be responsive
const LoginArea = () => {
  const bg = useColorModeValue('white', 'gray.800')
  return (
    <Flex minHeight={{ base: 'auto', md: '80vh' }}  width='full' h={["86vh", ""]} align='center' justifyContent='center'>
      <Box
        borderWidth={["none", 1]}
        p={[8,4]}
        bg={["", bg]}
        w={['full', 'md']}
        maxWidth='500px'
        borderRadius={4}
        textAlign='center'
        boxShadow={["none",'lg']}
      >
        <Box p={4}>
          <LoginHeader />
          <LoginForm />
        </Box>
      </Box>
    </Flex>
  );
};



const LoginHeader = () => {
  return (
    <Box textAlign='center'>
      <Heading>Connexion</Heading>
    </Box>
  )
}


const LoginForm = () => {
  const { postData } = usePostRequest('/api/user/login');
  const { showToast } = useCustomToast();
  const { login } = useAuth();
  const navigate = useNavigate();

  return (
    <Box my={8} textAlign='left'>
      <Formik
        initialValues={{ username: '', password: '', remember: false }}
        validate={validate}
        onSubmit={(values, actions) => {
          postData({
            username: values.username,
            password: values.password
          })
            .then(response => {
              const token = response.token;
              login(token);
              navigate('/');
              actions.setSubmitting(false);
            })
            .catch(error => {
              // console.error(error);
              showToast('Identifiants incorrects !', 2000, true, 'error')
              actions.setSubmitting(false);
            });
        }}
      >
        <Form>
          <Field name='username'>
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.username && form.touched.username}
              >
                <FormLabel>Nom d'utilisateur</FormLabel>
                <Input {...field} type='text' placeholder='Pseudo' />
                <FormErrorMessage>{form.errors.username}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name='password'>
            {({ field, form }) => (
              <FormControl
                mt={4}
                isInvalid={form.errors.password && form.touched.password}
              >
                <FormLabel>Mot de passe</FormLabel>
                <Input {...field} type='password' placeholder='Mot de passe' />
                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Stack direction="row" justifyContent='space-between' mt={4}>
            <Box>
              <Field name='remember'>
                {({ field }) => (
                  <Checkbox {...field}>Rester Connecté</Checkbox>
                )}
              </Field>
            </Box>
            <Box>
              <Link color={`teal.500`}>Mot de passe oublié?</Link>
            </Box>
          </Stack>
          <Button
            color={'teal'}
            width='full'
            mt={4}
            type='submit'
          >
            Connexion
          </Button>
        </Form>
      </Formik>
    </Box>
  );
};

export default Login