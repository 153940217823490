import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  useColorMode,
  useColorModeValue,
  Stack
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/auth/AuthContext.jsx";
import { useNavigate } from "react-router-dom";
import logoImage from "./../assets/react.svg";
import { useCustomToast } from "../utils/hooks/toast.js";
import { useSocket } from '../utils/context/SocketContext';

import { FaSun, FaMoon, FaSignOutAlt, FaTerminal } from "react-icons/fa";
import { GiLifeBar } from "react-icons/gi";

function Navbar() {
  const { logout, isLoggedIn } = useAuth();
  const { showToast } = useCustomToast();
  const socket = useSocket();
  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();

  const bg = useColorModeValue("#3083ce", "#373C75");
  const color = useColorModeValue("black", "#373C75");

  useEffect(() => {
    if (!socket) return;


  }, [socket]);
  
  // Fonction de déconnexion
  const disconnection = async () => {
    logout();
    navigate("/login");
    showToast("Vous avez été déconnecté !", 2000, true, "success");
  };

  return (
    <Box as="nav" bg={bg} color={color} p={4}>
      <Flex align="center" maxW="1200px" mx="auto" justify="space-between">
        <Link to="/">
          <Flex align="center" color="white" gap="2">
            <img src={logoImage} alt="Logo" style={{ height: "50px" }} />
            <Text fontSize="xl" fontWeight="bold">Rift Arena</Text>
          </Flex>
        </Link>
        <Stack direction="row" spacing={4}>
        <IconButton
              icon={<GiLifeBar />}
              onClick={() => {
                navigate("/jeuvie");}}
              size="lg"
              variant="ghost"
              color="white"
              aria-label="jeu de la vie"
            />
          
          <IconButton
              icon={<FaTerminal />}
              onClick={() => {
                navigate("/terminal");}}
              size="lg"
              variant="ghost"
              color="white"
              aria-label="terminal"
            />
          {isLoggedIn && (
            <IconButton
              icon={<FaSignOutAlt />}
              onClick={disconnection}
              size="lg"
              variant="ghost"
              color="white"
              aria-label="Log Out"
            />
          )}
          <IconButton
            icon={colorMode === 'dark' ? <FaSun /> : <FaMoon />}
            onClick={toggleColorMode}
            size="lg"
            variant="ghost"
            color="white"
            aria-label="Toggle Color Mode"
          />
          
        </Stack>
      </Flex>
    </Box>
  );
}

export default Navbar;
