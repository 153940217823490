import React from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { ColorModeScript } from "@chakra-ui/color-mode";
import App from './App';
import theme from './theme';
import { SocketProvider } from './utils/context/SocketContext';
import { AuthProvider } from './utils/auth/AuthContext';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <SocketProvider>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <App />
        </SocketProvider>
      </AuthProvider>
    </ChakraProvider>
  </React.StrictMode>
);