import React from 'react';
import { Box, Text, Button, Center } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom'; // Si vous utilisez React Router


function Error({value}) {
    return (
        <Center height="87vh">
            <Box textAlign="center">
                <Text fontSize="2xl" fontWeight="bold" mt="4">
                   Page non existante
                </Text>
                <Text fontSize="lg" mt="2">
                    {value}
                </Text>
                <Button
                    as={RouterLink}
                    to="/"
                    bg="#3083ce"
                    color="white"
                    mt="4"
                >
                    Retour à la page d'accueil
                </Button>
            </Box>
        </Center>
    );
}

export default Error
