import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Home from './pages/Home';
import Login from './pages/Login';
import RiftArenaLobby from './pages/RiftArenaLobby';
import Terminal from './pages/Terminal'
import JeuDeLaVie from './pages/JeuDeLaVie';
import Error from './layout/Error';
import Header from './layout/Header';

function App() {
  return (

      <Router>
        <Header />
        <Routes>
          <Route path="login" element={<PublicRoute><Login /></PublicRoute>} />

          <Route path="" element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="arena-lobby" element={<PrivateRoute><RiftArenaLobby /></PrivateRoute>} />
          <Route path="terminal" element={<PrivateRoute><Terminal /></PrivateRoute>} />
          <Route path="jeuvie" element={<PrivateRoute><JeuDeLaVie /></PrivateRoute>} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>

  );
}
export default App;
