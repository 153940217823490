import React, { createContext, useContext, useState, useEffect } from 'react';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import { useAuth } from '../auth/AuthContext';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { username } = useAuth(); // Hook to get the current user's username

  useEffect(() => {
    
    const token = Cookies.get('token');
    
    // Establish a socket connection with the server
    const newSocket = io(process.env.REACT_APP_URL_BACKEND, {
      path: '/socket.io',
      query: { username }, // Send the username as part of the query string
      extraHeaders: {
        Authorization: `Bearer ${token}`, // Send the auth token in the headers
      },
    });

    // Set the new socket
    setSocket(newSocket);

    // Function to clean up the effect
    return () => {
      newSocket.close();
    };
  }, [username]); // Add username to the dependencies array to reconnect the socket when it changes

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
