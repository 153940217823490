import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export function useGetRequest(url) {
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!url) return;
        setLoading(true);

        async function fetchData() {
            try {
                const token = Cookies.get('token');
                const response = await fetch(`${process.env.REACT_APP_URL_BACKEND}${url}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                setData(data);

            } catch (err) {
                console.error('Erreur lors de la récupération des données:', err);
                console.log()
                setError(true);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [url]);

    return { isLoading, data, error };
}
