import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, VStack, Spinner, Container, SimpleGrid, Badge, Flex } from '@chakra-ui/react';
import { useGetRequest } from '../hook/useGetRequest';
import { useSocket } from '../utils/context/SocketContext';

const RiftArenaLobby = () => {
  const socket = useSocket();
  const { data, error, isLoading } = useGetRequest('/api/game/available-lobbies');
  const [lobbies, setLobbies] = useState([]);
  const [socketConnected, setSocketConnected] = useState(false);

  useEffect(() => {
    if (data) {
      setLobbies(data);
    }
  }, [data]);

  useEffect(() => {
    if (socket) {
      socket.on('game-connect', () => {
        setSocketConnected(true);
      });

      socket.on('game-disconnect', () => {
        setSocketConnected(false);
      });

      socket.on('game-gameCreated', (game) => {
        setLobbies(prevLobbies => [...prevLobbies, game]);
      });

      return () => {
        socket.off('connect');
        socket.off('disconnect');
        socket.off('gameCreated');
      };
    }
  }, [socket]); 

  return (
    <Container maxW='container.md' centerContent>
      <Flex justifyContent="flex-start" mb={2}>
          <Box borderRadius="full" width="10px" height="10px" bg={socketConnected ? 'green.500' : 'red.500'} mr={3} />
        </Flex>
      <Heading as='h1' size='xl' mb='8'>Lobbies disponibles</Heading>
      {isLoading ? (
        <Spinner size='xl' />
      ) : error ? (
        <Text>{error}</Text>
      ) : (
        <VStack spacing={5}>
          {lobbies.length > 0 ? (
            <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing='5'>
              {lobbies.map((lobby) => (
                <Box key={lobby.gameId} p='5' shadow='md' borderWidth='1px'>
                  <Heading fontSize='xl'>Lobby {lobby.gameId}</Heading>
                  <Text>Créateur: {lobby.creator}</Text>
                  <Badge mt='1' colorScheme='green'>
                    Joueurs: {lobby.players.length}
                  </Badge>
                </Box>
              ))}
            </SimpleGrid>
          ) : (
            <Text>Aucun lobby disponible actuellement.</Text>
          )}

        </VStack>
      )}
    </Container>
  );
};

export default RiftArenaLobby;
