import React, { useState, useEffect, useRef } from 'react';
import { Box, Input, Flex } from '@chakra-ui/react';
import { useSocket } from '../utils/context/SocketContext';
import { useAuth } from "../utils/auth/AuthContext";

const Terminal = () => {
    const socket = useSocket();
    const { username } = useAuth();
    const [input, setInput] = useState('');
    const [commandResponsePairs, setCommandResponsePairs] = useState([]);
    const endOfMessagesRef = useRef(null);

    useEffect(() => {
        if (!socket) return;

        const handleNewOutput = (data) => {
        
            setCommandResponsePairs(prevPairs => {
                // Vérifier si la réponse correspond à une commande existante
                let commandExists = false;
        
                const updatedPairs = prevPairs.map(pair => {
                    if (pair.id === data.id) {
                        commandExists = true;
                        return { ...pair, response: data.response };
                    }
                    return pair;
                });
        
                // Si la réponse ne correspond à aucune commande existante (message entrant), ajoutez-la
                if (!commandExists && data.response) {
                    updatedPairs.push({
                        id: Date.now(), // Créez un nouvel ID unique
                        command: `Received: ${data.response}`,
                        response: '' // Cette ligne peut être supprimée si vous ne voulez pas une propriété réponse vide.
                    });
                }
        
                return updatedPairs;
            });
        };
        
        

        socket.on('terminal-output', handleNewOutput);

        return () => socket.off('terminal-output', handleNewOutput);
    }, [socket]);

    useEffect(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [commandResponsePairs]);

    const handleInput = (event) => {
        if (event.key === 'Enter' && input.trim()) {
            const id = Date.now()
            socket.emit('terminal-command', { username, command: input, id });

            setCommandResponsePairs(prevPairs => [
                ...prevPairs,
                { id, command: input, response: "Traitement en cours..." },
            ]);

            setInput('');
        }
    };

    return (
        <Box
            bg="black"
            color="green"
            fontFamily="monospace"
            minHeight="400px"
            maxHeight="600px"
            overflowY="auto"
            borderX="1px solid"
            borderColor="green.500"
            mx={2}
            mt={4}
            p={4}
        >
            {/* Affichage des paires de commandes et de réponses */}
            {commandResponsePairs.map((pair, index) => (
                <Flex key={index} direction="column" mb={2} >
                    <Box as="span">{`> ${pair.command}`}</Box>
                    <Box as="span" color="gray.500">{pair.response}</Box>
                </Flex>
            ))}
            <Box ref={endOfMessagesRef} />
            {/* Input pour saisir les commandes */}
            <Flex mt={2}>
                <Box as="span" color="green.500">{">"}</Box>
                <Input
                    variant="unstyled"
                    placeholder="Type your command..."
                    size="sm"
                    focusBorderColor="green.500"
                    color="green"
                    _placeholder={{ color: 'green.200' }}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleInput}
                    flex={1}
                    ml={2}
                />
            </Flex>
        </Box>
    );
};

export default Terminal;
